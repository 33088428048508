import type {ReactElement} from 'react'
import {AuthShell, AuthForm} from 'core/components'

const LoginPage = () => (
  <AuthShell brandTitle="Admin">
    <AuthForm variant="admin" />
  </AuthShell>
)

LoginPage.getLayout = (page: ReactElement) => page

export default LoginPage
